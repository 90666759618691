@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
    color: #fff;
}

.header-description {
    background-color: black;
    @apply pt-1 pb-2;
}

.card-body {
    @apply pt-2 pb-2 pl-5 pr-5;
}

.between-arrow {
    position: relative;
    &::before {
        content: "";
        background: url(https://aog-conf.b-cdn.net/2024/right-arrow.gif);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 32px;
        height: 32px;
        top: 8px;
        transform: translateX(-120%);
    }
    &::after {
        content: "";
        background: url(https://aog-conf.b-cdn.net/2024/right-arrow.gif);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 32px;
        height: 32px;
        top: -2px;
        transform: translateX(20%) rotate(180deg);
    }
}

.between-stars {
    position: relative;
    &::before {
        content: "";
        background: url(https://aog-conf.b-cdn.net/2024/stars-rev2.webp);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 32px;
        height: 32px;
        top: 4px;
        transform: translateX(-100%);
    }
    &::after {
        content: "";
        background: url(https://aog-conf.b-cdn.net/2024/stars-rev2.webp);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 32px;
        height: 32px;
        top: 0px;
        transform: translateX(10%);
    }
}

.card-badge-1 {
    position: relative;
    &::after {
        content: "";
        background: url(https://aog-conf.b-cdn.net/2024/badge1.webp);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 64px;
        height: 64px;
        right: 0;
        rotate: 15deg;
    }
}

html,
body {
    background-color: black;
}

@keyframes pulse {
    0% {
        background-color: rgba(226, 226, 226, 0.05);
    }
    50% {
        background-color: rgba(226, 226, 226, 0.1);
    }
    100% {
        background-color: rgba(226, 226, 226, 0.05);
    }
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 200px;
    }
}

@keyframes blink-caret {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: orange;
    }
}

#loadingContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    overflow: hidden;
    .typewriter {
        #loadingText {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            overflow: hidden; /* Ensures the content is not revealed until the animation */
            border-right: 0.15em solid orange; /* The typwriter cursor */
            white-space: nowrap; /* Keeps the content on a single line */
            margin: 0 auto; /* Gives that scrolling effect as the typing happens */
            letter-spacing: 0.15em; /* Adjust as needed */
            animation: typing 2s steps(50, end),
                blink-caret 0.75s step-end infinite;
        }
    }
    #loadingObject1 {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 45%;
        left: 50%;
        opacity: 1;
        animation: pulse 1.5s ease-in-out infinite;
        &::after {
            content: "";
            width: 48px;
            height: 24px;
            position: absolute;
            top: calc(100% - 8px);
            left: 100%;
            opacity: 1;
            animation: pulse 1.5s ease-in-out infinite;
        }
    }
    #loadingObject2 {
        width: 240px;
        height: 160px;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 1;
        animation: pulse 1.5s ease-in-out infinite;
    }
    #loadingObject3 {
        width: 64px;
        height: 32px;
        position: absolute;
        top: 30%;
        left: 10%;
        opacity: 1;
        animation: pulse 1.5s ease-in-out infinite;
    }
}
